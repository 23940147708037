.paper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-bottom: 30px;
}

#location, #owner, #value, #sale, #land, #resi, #comm, #moho, #misc {
  box-shadow: none;
}

.parcelTitle {
  text-align: left;
  color: #1976d2;
}

.parcelLabel {
  text-align: left;
  display: inline-block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.9rem;
}

.parcelContent {
  text-align: left;
  display: inline-block;
  color: black;
  font-size: 0.9rem;
  margin-right: 5%;
}

.parcelContentTable {
  color: black;
  font-size: 0.9rem;
}

.imageList {
  margin-top: 10px;
}

.imageItem {
  cursor: pointer;
}
