.paper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-bottom: 50px;
}

.appBar {
  display: flex;
  flex-direction: row;
}

.heading {
  color: #fff;
  text-decoration: none;
  margin-left: 25px;
}

.heading:hover {
  color: #fff;
}

.image {
  margin-left: 15px;
}

.toolbar {
  display: flex;
  justify-content: flex-end;
  width: 400px;
}

.profile {
  display: flex;
  justify-content: space-between;
  width: 400px;
}

.userName {
  display: flex;
  align-items: center;
}

.brandContainer {
  display: flex;
  align-items: center;
}

.white {
  color: #fff;
  background-color: #fff;
}

.grid {
  text-align: center;
}

.form {
  width: 300px;
}

.avatar {
  margin: 5px;
}

.contactForm {
  width: 100%;
  margin-top: 15px;
}

.submit {
  margin-top: 15px;
}
