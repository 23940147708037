.paper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.root.MuiTextField-root {
  margin: 5px;
}

.avatar {
  margin: 5px;
}

.form {
  width: 100%;
  margin-top: 15px;
}

.submit {
  margin-top: 15px;
}
