.containerPaper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-bottom: 30px;
}

.loading {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mapContainer {
  /* TODO - for location search and user current location later
  height: 482px; */
  height: 540px;
  width: 100%;
  margin-top: 10px;
  margin-left: 10px;
}

.paper {
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
  cursor: default;
}

.paper:hover {
  z-index: 2;
}

.mapContainer .mapButton {
  background-color: #fff;
  margin-bottom: 3vh;
  color: #555;
}

.container {
  padding: 0px;
}

.marginBottom {
  margin-bottom: 30px;
}

.list {
  width: 100%;
}

.parcelCard {
  width: 95%;
}

.subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.paper .paperContent {
  margin-left: 8px;
  font-size: 12px;
}

.parcelMapContainer {
  height: 400px;
  width: 100%;
  margin-bottom: 20px;
}